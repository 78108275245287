import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Select, MenuItem, Checkbox, FormControlLabel, Button, InputLabel, FormControl, Box } from '@mui/material';
import TLHEndpoints from 'services/TLHEndpoints';
import { Stadium, YouTube } from '@mui/icons-material';

const staffTypeOptions = [
  "TEACHER",
  "ADMINISTRATIVE_WORKER",
  "TEACHER_AND_ADMINISTRATIVE_WORKER",
  "MANAGER",
];

export default function AddTLHStaffMember({handleClose, staffMemberInformation}) {

  console.log(staffMemberInformation)

  const formatSortCode = (value) => {
    if (!value) return value;
    const numericValue = value.replace(/[^\d]/g, '').slice(0, 6); // Remove non-numeric characters and limit to 6 digits
    return numericValue
      .replace(/(\d{2})(\d{0,2})?(\d{0,2})?/, (match, p1, p2, p3) => {
        if (p3) return `${p1}-${p2}-${p3}`;
        if (p2) return `${p1}-${p2}`;
        return `${p1}`;
      });
  };

  const formatAccountNumber = (value) => {
    return value.replace(/[^\d]/g, '').slice(0, 8); // Remove non-numeric characters and limit to 8 digits
  };

  const handleSortCodeChange = (event) => {
    const formattedSortCode = formatSortCode(event.target.value);
    formik.setFieldValue('sortCode', formattedSortCode);
  };

  const handleAccountNumberChange = (event) => {
    const formattedAccountNumber = formatAccountNumber(event.target.value);
    formik.setFieldValue('accountNumber', formattedAccountNumber);
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, ''); // Remove all non-digit characters
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 5) return phoneNumber;
    if (phoneNumberLength < 9) {
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4)}`;
    }
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 7)} ${phoneNumber.slice(7, 11)}`;
  };

  const handlePhoneNumberChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    formik.setFieldValue('phoneNumber', formattedPhoneNumber);
  };

  const formik = useFormik({
    initialValues: {
      firstName: staffMemberInformation?.firstName || '',
      lastName: staffMemberInformation?.lastName || '',
      staffTypes: staffMemberInformation?.staffTypes || [],
      hourlySalary: staffMemberInformation?.hourlySalary || '',
      startDate: staffMemberInformation?.startDate || '',
      sortCode: staffMemberInformation?.sortCode || '',
      accountNumber: staffMemberInformation?.accountNumber || '',
      postCode: staffMemberInformation?.postCode || '',
      addressFirstLine: staffMemberInformation?.addressFirstLine || '',
      addressSecondLine: staffMemberInformation?.addressSecondLine || '',
      leavingDate: staffMemberInformation?.leavingDate || '',
      dbsCompletionDate: staffMemberInformation?.dbsCompletionDate || '',
      isCurrentlyEmployed: staffMemberInformation?.isCurrentlyEmployed || false,
      communicationNotes: '',
      workEmail: staffMemberInformation?.workEmail || '',
      personalEmail: staffMemberInformation?.personalEmail || '',
      phoneNumber: staffMemberInformation?.phoneNumber || '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      staffTypes: Yup.array().min(1, 'Select at least one staff type').required('Required'),
      hourlySalary: Yup.number().typeError('Must be a valid number').positive('Must be positive').required('Required'),
      sortCode: Yup.string().required('Sort Code is required'),
      accountNumber: Yup.string().required('Account Number is required'),
      postCode: Yup.string().required('Post Code is required'),
      addressFirstLine: Yup.string().required('Address First Line is required'),
      workEmail: Yup.string().email('Invalid email').required('Work Email is required'),
      personalEmail: Yup.string().email('Invalid email').required('Personal Email is required'),
      phoneNumber: Yup.string().required('Phone Number is required'),
      startDate: Yup.date().required('Required'),
    }),
    onSubmit: (values) => {
      console.log('Formik Errors:', formik.errors);

      console.log("Submitting");
      if (staffMemberInformation) {
        console.log("Edit submitted")
        TLHEndpoints.editTLHStaffMember(values, staffMemberInformation.staffId)
      } else {
        console.log("New staff submitted")
        TLHEndpoints.addNewTLHStaffMember(values)
      }
      handleClose()
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          maxHeight: '80vh',
          overflowY: 'auto',
          padding: 2,
        }}
      >
        <div className="relative w-full mb-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flexBasis: '45%' }}>
            <TextField
              id="firstName"
              name="firstName"
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              fullWidth
              InputProps={{
                style: { padding: '10px' },
              }}
            />
          </div>

          <div style={{ flexBasis: '45%' }}>
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              fullWidth
              InputProps={{
                style: { padding: '10px' },
              }}
            />
          </div>
        </div>

        <FormControl fullWidth margin="normal">
          <InputLabel id="staffType-label">Staff Types</InputLabel>
          <Select
            labelId="staffType-label"
            id="staffTypes"
            name="staffTypes"
            multiple
            value={formik.values.staffTypes}
            onChange={formik.handleChange}
            error={formik.touched.staffTypes && Boolean(formik.errors.staffTypes)}
            helperText={formik.touched.staffTypes && formik.errors.staffTypes}
            renderValue={(selected) => selected.join(', ')}
          >
            {staffTypeOptions.map((type) => (
              <MenuItem key={type} value={type}>
                <Checkbox checked={formik.values.staffTypes.includes(type)} />
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          id="hourlySalary"
          name="hourlySalary"
          label="Hourly Salary"
          type="number"
          // min={0} need to set minimum value
          value={formik.values.hourlySalary}
          onChange={formik.handleChange}
          error={formik.touched.hourlySalary && Boolean(formik.errors.hourlySalary)}
          helperText={formik.touched.hourlySalary && formik.errors.hourlySalary}
          fullWidth
          margin="normal"
          InputProps={{
            style: { padding: '10px' },
          }}
        />

        <TextField
          id="startDate"
          name="startDate"
          label="Start Date"
          type="date"
          value={formik.values.startDate}
          onChange={formik.handleChange}
          error={formik.touched.startDate && Boolean(formik.errors.startDate)}
          helperText={formik.touched.startDate && formik.errors.startDate}
          InputLabelProps={{ shrink: true }}
          fullWidth
          margin="normal"
          InputProps={{
            style: { padding: '10px' },
          }}
        />

        <TextField
          id="sortCode"
          name="sortCode"
          label="Sort Code"
          value={formik.values.sortCode}
          onChange={handleSortCodeChange}
          error={formik.touched.sortCode && Boolean(formik.errors.sortCode)}
          helperText={formik.touched.sortCode && formik.errors.sortCode}
          fullWidth
          margin="normal"
          InputProps={{
            style: { padding: '10px' },
          }}
        />

        <TextField
          id="accountNumber"
          name="accountNumber"
          label="Account Number"
          value={formik.values.accountNumber}
          onChange={handleAccountNumberChange}
          error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
          helperText={formik.touched.accountNumber && formik.errors.accountNumber}
          fullWidth
          margin="normal"
          InputProps={{
            style: { padding: '10px' },
          }}
        />

        <TextField
          id="postCode"
          name="postCode"
          label="Post Code"
          value={formik.values.postCode}
          onChange={formik.handleChange}
          error={formik.touched.postCode && Boolean(formik.errors.postCode)}
          helperText={formik.touched.postCode && formik.errors.postCode}
          fullWidth
          margin="normal"
          InputProps={{
            style: { padding: '10px' },
          }}
        />

        <TextField
          id="addressFirstLine"
          name="addressFirstLine"
          label="Address First Line"
          value={formik.values.addressFirstLine}
          onChange={formik.handleChange}
          error={formik.touched.addressFirstLine && Boolean(formik.errors.addressFirstLine)}
          helperText={formik.touched.addressFirstLine && formik.errors.addressFirstLine}
          fullWidth
          margin="normal"
          InputProps={{
            style: { padding: '10px' },
          }}
        />

        <TextField
          id="addressSecondLine"
          name="addressSecondLine"
          label="Address Second Line"
          value={formik.values.addressSecondLine}
          onChange={formik.handleChange}
          fullWidth
          margin="normal"
          InputProps={{
            style: { padding: '10px' },
          }}
        />

        <TextField
          id="leavingDate"
          name="leavingDate"
          label="Leaving Date"
          type="date"
          value={formik.values.leavingDate}
          onChange={formik.handleChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
          margin="normal"
          InputProps={{
            style: { padding: '10px' },
          }}
        />

        <TextField
          id="dbsCompletionDate"
          name="dbsCompletionDate"
          label="DBS Completion Date"
          type="date"
          value={formik.values.dbsCompletionDate}
          onChange={formik.handleChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
          margin="normal"
          InputProps={{
            style: { padding: '10px' },
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              id="isCurrentlyEmployed"
              name="isCurrentlyEmployed"
              checked={formik.values.isCurrentlyEmployed}
              onChange={formik.handleChange}
            />
          }
          label="Currently Employed"
        />

        {staffMemberInformation ? 
          <></>
          :
          <TextField
            id="communicationNotes"
            name="communicationNotes"
            label="Communication Notes"
            multiline
            rows={4}
            value={formik.values.communicationNotes}
            onChange={formik.handleChange}
            fullWidth
            margin="normal"
            InputProps={{
              style: { padding: '10px' },
            }}
          />
        }

        <TextField
          id="workEmail"
          name="workEmail"
          label="Work Email"
          value={formik.values.workEmail}
          onChange={formik.handleChange}
          error={formik.touched.workEmail && Boolean(formik.errors.workEmail)}
          helperText={formik.touched.workEmail && formik.errors.workEmail}
          fullWidth
          margin="normal"
          InputProps={{
            style: { padding: '10px' },
          }}
        />

        <TextField
          id="personalEmail"
          name="personalEmail"
          label="Personal Email"
          value={formik.values.personalEmail}
          onChange={formik.handleChange}
          error={formik.touched.personalEmail && Boolean(formik.errors.personalEmail)}
          helperText={formik.touched.personalEmail && formik.errors.personalEmail}
          fullWidth
          margin="normal"
          InputProps={{
            style: { padding: '10px' },
          }}
        />

        <TextField
          id="phoneNumber"
          name="phoneNumber"
          label="Phone Number"
          value={formik.values.phoneNumber}
          onChange={handlePhoneNumberChange}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          fullWidth
          margin="normal"
          InputProps={{
            style: { padding: '10px' },
          }}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Submit
        </Button>
      </Box>
    </form>
  );
}