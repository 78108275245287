import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import {useParams} from "react-router-dom";

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Modal from '@mui/material/Modal';
import { Typography } from '@material-ui/core';

import IndividualChildAssessment from 'theLearningHub/layouts/HelperComponents/IndividualChildAssessment';
import TLHEndpoints from 'services/TLHEndpoints';
import TLHClassesAPICalls from 'services/TLHClassesAPICalls';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@material-ui/core/TextField';

export default function IndividualTLHStudentProfile({isEnquiryNotFromToday, enquiryInfo}) {

  console.log("Logging enquiry info")
  console.log(enquiryInfo)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  const [openSuccessfulSubmissionModal, setOpenSuccessfulSubmissionModal] = useState(false);
  const [isGettingStudentInfo, setIsGettingStudentInfo] = useState(true)
  const [tlhStudentData, setTLHStudentData] = useState([])
  const [newlyAddedNotes, setNewlyAddedNotes] = useState([])
  const tableContainerRef = useRef(null)

  var JUST_NOW = "Just Now"

  const { tlhStudentId } = useParams();

  useEffect(() => {
    if (isGettingStudentInfo) {
      getIndividualStudentInfo()
      // getAllCommunicationNotesForSpecificTLHStudent()
    }
  
  }, [isGettingStudentInfo]);


  function getIndividualStudentInfo() {
    TLHClassesAPICalls.getIndividualTLHStudentInfo(tlhStudentId).then(result => {
      console.log(result.data)
      setTLHStudentData(result.data)
      console.log("Notes info")
      console.log(tlhStudentData.customerNotes)
      setIsGettingStudentInfo(false)
    })
  }

  function formattedDate(dateString ) {
     
    if (dateString == JUST_NOW) {
      return dateString
    }

    // Parse the date string
    const date = new Date(dateString);

    // Create an options object for the DateTimeFormat function
    const options = {
        year: 'numeric', month: 'long', day: 'numeric',
        hour: '2-digit', minute: '2-digit', hour12: true
    };

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate
}

  const styles = {
    tableContainer: {
      width: '100%',
      maxHeight: '300px', // Adjust the height as needed
      overflowY: 'auto',
      border: '1px solid #ccc'
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
    },
    cell: {
      padding: '8px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
    }
  };



  function handleCloseSuccessfulSubmissionModal() {

  }

  const validationSchema = yup.object({
    parentLastName: yup.string('Enter the parent last name'),
    parentFirstName: yup.string('Enter the parent first name'),
    enquiryDate: yup.date('When did the enquiry come in'),
  });


  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, ''); // Remove all non-digit characters
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 5) return phoneNumber;
    if (phoneNumberLength < 9) {
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4)}`;
    }
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 7)} ${phoneNumber.slice(7, 11)}`;
  };

  const handlePhoneNumberChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    formik.setFieldValue('phoneNumber', formattedPhoneNumber);
  };

  const handleAltPhoneNumberChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    formik.setFieldValue('altPhoneNumber', formattedPhoneNumber);
  };

  const formik = useFormik({
    initialValues: {
      parentFirstName: enquiryInfo?.parentFirstName || '',
      parentLastName: enquiryInfo?.parentLastname || '',
      phoneNumber: enquiryInfo?.phoneNumber || '',
      altPhoneNumber: enquiryInfo?.altPhoneNumber || '',
      email: enquiryInfo?.email || '',
      addressLine1: enquiryInfo?.addressLine1 || '',
      addressLine2: enquiryInfo?.addressLine2 || '',
      postcode: enquiryInfo?.postcode || '',
      enquirySource: enquiryInfo?.enquirySource || '',
      assessmentMeetingDate: enquiryInfo?.assessmentMeetingDate || '',
      assessmentMeetingTime: enquiryInfo?.assessmentMeetingTime ? new Date(`1970-01-01T${enquiryInfo.assessmentMeetingTime}`) : null,
      enquiryDate: enquiryInfo?.enquiryDate ? enquiryInfo.enquiryDate.split('T')[0] : '',
      notes: '',
      followUpDate: enquiryInfo?.followUpDate ? enquiryInfo.followUpDate.split('T')[0] : '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
   
      setOpenSuccessfulSubmissionModal(true);
    },
    
  });

  return (
    <div>
      {isGettingStudentInfo ? 
      <div>
        Loading Data...
      </div> 
      :
      <form
        onSubmit={formik.handleSubmit}
        style={{padding: '20px'}}
      >
        <Typography variant="h4" align='center' style={{padding: "15px"}}>
          {tlhStudentData.firstName + " " + tlhStudentData.surname}
        </Typography>

        Parent name: {tlhStudentData.assessmentInformation.parentBookingEnquiryDetails.parentFirstName + " " + tlhStudentData.assessmentInformation.parentBookingEnquiryDetails.parentLastname}

        <br></br>
        <br></br>

        Parent Email: {tlhStudentData.parentEmail}

        <br></br>
        <br></br>

        Parent phone number: {tlhStudentData.phoneNumber}

        <br></br>
        <br></br>
        
        {tlhStudentData.communicationNotes.length == 0 ?
          <div className="table-container" style={{textAlign: "center", fontWeight: "bold", fontSize: "25px"}}>
            No customer notes
          </div>
          :
          <div>
          <div style={{textAlign: "center", fontWeight: "bolder", fontSize: "x-large", paddingBottom: "20px"}}>
          <label> Communication Notes </label>
        </div>
        <div ref={tableContainerRef} style={styles.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {tlhStudentData.communicationNotes.map((note) => (
                <TableRow key={note.communicationNoteId}>
                  <TableCell>{formattedDate(note.noteCreationTimestamp)}</TableCell>
                  <TableCell>{note.communicationNote}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>}

   

  
        {/* <button
          className="uppercase px-6 py-3 rounded shadow-lg hover:shadow-lg outline-none focus:outline mr-1 mb-1 w-full"
          type="submit"
        >Click to update student details</button> */}
      </form>
      }

      <Modal
        open={openSuccessfulSubmissionModal}
        onClose={handleCloseSuccessfulSubmissionModal}
      >
        <Box sx={style}>
          <Typography variant="h5" align='center' style={{padding: "15px"}}>
            Student Details Updated
          </Typography>
        </Box>
      </Modal>

    </div>
  );
}
