import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@material-ui/core';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import TLHEndpoints from 'services/TLHEndpoints';
import TLHClassesAPICalls from 'services/TLHClassesAPICalls';
import AddTermDatesModel from 'theLearningHub/layouts/HelperComponents/AddTermDatesModel';
import AddTLHStaffMember from 'theLearningHub/layouts/HelperComponents/AddTLHStaffMember';
import * as CONSTANTS from "../../StringConstants"


export default function TLHAdminDashboard() {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  let history = useHistory();

  const [addTermDatesModel, setAddTermDatesModel] = useState(false);
  const [AddTLHStaffMemberModalIsOpen, setAddTlhStaffMemberModalIsOpen] = useState(false)

  const handleOpenAddTermDatesModel = () => setAddTermDatesModel(true);
  const handleOpenAddTLHStaffMemberModel = () => setAddTlhStaffMemberModalIsOpen(true);

  const handleCloseAddTLHStaffMemberModel = () => setAddTlhStaffMemberModalIsOpen(false);


  function handleCloseAddTermDatesModel(upToDate) {
    setAddTermDatesModel(false);
    if (!upToDate) {
      window.location.reload();
    }
  }

  const [isLoadingAssessments, setIsLoadingAssessments] = useState(true)
  const [isLoadingTermDates, setIsLoadingTermDates] = useState(true)

  const [assessmentsData, setAssessmentsData] = useState()
  const [termDates, setTermDates] = useState()
  

  useEffect(() => {
    if (isLoadingAssessments) {
      getAllAssessmentTypesInfo()
    }
    if (isLoadingTermDates) {
      getAllTermDates()
    }

  });

  function getAllAssessmentTypesInfo() {
    TLHEndpoints.getAllAssessmentTypesInfo().then(result => {
      // console.log(result.data)
      // setMockExamSets(result.data)
      // console.log("Assessment Types")
      // console.log(result.data)
      setAssessmentsData(result.data)
      setIsLoadingAssessments(false)
    })
  }

  function getAllTermDates() {
    TLHClassesAPICalls.getAllTermDates().then(result => {
      // console.log(result.data)
      // setMockExamSets(result.data)
      console.log("Term Dates")
      console.log(result.data)
      setTermDates(result.data)
      setIsLoadingTermDates(false)
      console.log(termDates)
    })
  }

  const validationSchema = yup.object({
    yearGroup: yup
      .string('')
      .required(),
      subject: yup
      .string('')
      .required(),
      durationInMins: yup
      .number()
      .integer('Duration must be an integer')
      .positive()
      .required(),
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        yearGroup: '',
        subject: '',
        durationInMins: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        console.log(formik.values)
        TLHEndpoints.addNewAssessmentType(formik.values)
      },
    });

    function viewTLHStaffMembers() {
      history.push(CONSTANTS.TLHStaffMembers)
    }

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        style={{padding: '20px'}}
        >
         <Typography variant="h4" align='center' style={{padding: "15px"}}>
           Admin Dashboard
          </Typography>

          <div style={{textAlign: 'center', padding: '30px'}}>
            <button 
                style={{
                  padding: '10px 20px',
                  fontSize: '18px',
                  color: 'white',
                  backgroundColor: '#0056b3',
                  border: 'none',
                  borderRadius: '5px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
              }}
              onMouseOver={(e) => {
                  e.target.style.backgroundColor = '#004085';
                  e.target.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)';
              }}
              onMouseOut={(e) => {
                  e.target.style.backgroundColor = '#0056b3';
                  e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
              }}
              onClick={handleOpenAddTLHStaffMemberModel}
              type="submit"
            >
                Add New Staff Member
            </button>
          </div>


          <div style={{textAlign: 'center', padding: '30px'}}>
            <button 
                style={{
                  padding: '10px 20px',
                  fontSize: '18px',
                  color: 'white',
                  backgroundColor: '#0056b3',
                  border: 'none',
                  borderRadius: '5px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
              }}
              onMouseOver={(e) => {
                  e.target.style.backgroundColor = '#004085';
                  e.target.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)';
              }}
              onMouseOut={(e) => {
                  e.target.style.backgroundColor = '#0056b3';
                  e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
              }}
              onClick={viewTLHStaffMembers}
              type="submit"
            >
                View All Staff Members 
            </button>
          </div>


          <Typography
            variant="h5"
            style={{paddingBottom: "20px"}}
          >
            New Assessment Details (To add a new assessment to the system)
          </Typography>

          {isLoadingAssessments ?
            <div className="table-container" style={{textAlign: "center", fontWeight: "bold", fontSize: "25px"}}>
              Loading Assessments
            </div>
            :
          <div className="table-container">
            <table>
              <thead >
                <tr>
                  <th>Year group</th>
                  <th>Assessment Type</th>
                  <th>Duration</th>
                  <th>Blank Copies in Folder</th>
                  <th>Blank Copies to Print Tonight</th>
                  <th>Number needed to be marked</th>
                  <th>Blank Copy URL</th>
                  <th>Mark Scheme URL</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {assessmentsData.map((assessment) => (
                  <tr key={assessment.assessmentMaterialId}>
                    <td>{assessment.assessmentYearGroup}</td>
                    <td>{assessment.assessmentSubject}</td>
                    <td>{assessment.durationInMins}</td>
                    <td>{assessment.numberBlankCopiesCurrentlyPrinted}</td>
                    <td>{assessment.numberBlankCopiesToBePrintedTonight}</td>
                    <td>{assessment.numberOfCompletedAssessmentsThatNeedToBeMarked}</td>
                    <td>{assessment.pathToAssessmentStudentCopy}</td>
                    <td>{assessment.pathToAssessmentMarkScheme}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>}

          <div style={{ flexBasis: '45%' }}> 
            <TextField
              id="durationInMins"
              name="durationInMins"
              label="Duration (Mins)"
              type="number"
              value={formik.values.durationInMins}
              onChange={formik.handleChange}
              error={formik.touched.durationInMins && Boolean(formik.errors.durationInMins)}
              helperText={formik.touched.durationInMins && formik.errors.durationInMins}
            />
          </div>

        <FormControl fullWidth style={{ maxWidth: '250px', padding: '20px' }}>
          <InputLabel id="year-group-label" style={{paddingLeft: '50px', paddingTop: '10px'}}>Year Group</InputLabel>
          <Select
            labelId="yearGroup"
            id="yearGroup"
            name="yearGroup"  
            value={formik.values.yearGroup}
            label="Year Group"
            onChange={formik.handleChange}
            style={{textAlign: 'center'}}
            MenuProps={{
              PaperProps: {
                style: {
                  maxWidth: '280px',
                },
              },
            }}
          >
            <MenuItem style={{ width: '100%'}} value="YEAR_1">Year 1</MenuItem>
            <MenuItem style={{ width: '100%'}} value="YEAR_2">Year 2</MenuItem>
            <MenuItem style={{ width: '100%'}} value="YEAR_3">Year 3</MenuItem>
            <MenuItem style={{ width: '100%'}} value="YEAR_4">Year 4</MenuItem>
            <MenuItem style={{ width: '100%'}} value="YEAR_5">Year 5</MenuItem>
            <MenuItem style={{ width: '100%'}} value="YEAR_6">Year 6</MenuItem>
            <MenuItem style={{ width: '100%'}} value="KS3_A">KS3 A</MenuItem>
            <MenuItem style={{ width: '100%'}} value="KS3_B">KS3 B</MenuItem>
            <MenuItem style={{ width: '100%'}} value="KS3_C">KS3 C</MenuItem>
            <MenuItem style={{ width: '100%'}} value="GCSE_1">GCSE 1</MenuItem>
            <MenuItem style={{ width: '100%'}} value="GCSE_2">GCSE 2</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ maxWidth: '250px', padding: '20px' }}>
          <InputLabel id="subject-label" style={{paddingLeft: '50px', paddingTop: '10px'}}>Subject</InputLabel>
          <Select
            labelId="subject"
            id="subject"
            name="subject"  
            value={formik.values.subject}
            label="Subject"
            onChange={formik.handleChange}
            style={{textAlign: 'center'}}
            MenuProps={{
              PaperProps: {
                style: {
                  maxWidth: '280px',
                },
              },
            }}
          >
            <MenuItem style={{ width: '100%'}} value="MATHS">Maths</MenuItem>
            <MenuItem style={{ width: '100%'}} value="ENGLISH">English</MenuItem>
            <MenuItem style={{ width: '100%'}} value="VR_NVR">Verbal and Non-Verbal Reasoning</MenuItem>
            <MenuItem style={{ width: '100%'}} value="SCIENCE">Science</MenuItem>
          </Select>
        </FormControl>

        <button
            colour="primary"
            className="uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
            type="submit"
        > Add Assessment to System</button>
      </form>

      { isLoadingTermDates ? <></> :
      <>
      <Typography style={{padding: "15px"}}>
          {/* {dayOfTheWeek} */}Term Dates
        </Typography>

      <Table size="small" style={{tableLayout: 'auto'}}>
          <TableHead >
            <TableRow>
              <TableCell>School Year</TableCell>
              <TableCell>Autumn Term Start</TableCell>
              <TableCell>Autumn Term Half Term</TableCell>
              <TableCell>Autumn Term Restart</TableCell>
              <TableCell>Autumn Term End</TableCell>

              <TableCell>Spring Term Start</TableCell>
              <TableCell>Spring Term Half Term</TableCell>
              <TableCell>Spring Term Restart</TableCell>
              <TableCell>Spring Term End</TableCell>

              <TableCell>Summer Term Start</TableCell>
              <TableCell>Summer Term Half Term</TableCell>
              <TableCell>Summer Term Restart</TableCell>
              <TableCell>Summer Term End</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{textAlign: "center"}}>
          {termDates.map((termDate) => (
              <TableRow key={termDate.classId}>
                <TableCell> {termDate.commencingSchoolYear}</TableCell>

                <TableCell>{termDate.autumnTermStartDate}</TableCell>
                <TableCell>{termDate.autumnTermHalfTermStartDate}</TableCell>
                <TableCell>{termDate.autumnTermRestartDate}</TableCell>
                <TableCell>{termDate.autumnTermEnd}</TableCell>

                <TableCell>{termDate.springTermStartDate}</TableCell>
                <TableCell>{termDate.springTermHalfTermStartDate}</TableCell>
                <TableCell>{termDate.springTermRestartDate}</TableCell>
                <TableCell>{termDate.springTermEnd}</TableCell>

                <TableCell>{termDate.summerTermStartDate}</TableCell>
                <TableCell>{termDate.summerTermHalfTermStartDate}</TableCell>
                <TableCell>{termDate.summerTermRestartDate}</TableCell>
                <TableCell>{termDate.summerTermEnd}</TableCell>

              </TableRow>
            ))} 
          </TableBody>
          
        </Table> 
        </>
      }
      <div style={{textAlign: 'center', padding: '30px'}}>
        <button 
            style={{
              padding: '10px 20px',
              fontSize: '18px',
              color: 'white',
              backgroundColor: '#0056b3',
              border: 'none',
              borderRadius: '5px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
          }}
          onMouseOver={(e) => {
              e.target.style.backgroundColor = '#004085';
              e.target.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)';
          }}
          onMouseOut={(e) => {
              e.target.style.backgroundColor = '#0056b3';
              e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
          }}
          onClick={handleOpenAddTermDatesModel}
          type="submit"
        >
            Add Term Dates for the Year
        </button>
      </div>



      <Modal
        open={addTermDatesModel}
        onClose={handleCloseAddTermDatesModel}
        // aria-labelledby="modal-add-mock-exam"
        // aria-describedby="modal-able-to-add-a-set"
      >
        <Box sx={style}>
          <AddTermDatesModel
            handleClose={handleCloseAddTermDatesModel}
          />
        </Box>
      </Modal>
      
      <Modal
        open={AddTLHStaffMemberModalIsOpen}
        onClose={handleCloseAddTLHStaffMemberModel}
        // aria-labelledby="modal-add-mock-exam"
        // aria-describedby="modal-able-to-add-a-set"
      >
        <Box sx={style}>
          <AddTLHStaffMember
            handleClose={handleCloseAddTLHStaffMemberModel}
          />
        </Box>
      </Modal>
      
    </div>
  );
}

